import React from "react";
import { Button, Card, Form, Typography, Space } from "antd";
import { useHistory } from "react-router-dom";
import { routes } from "../../utils/routesConfig";
import { Autocomplete } from "../../components/Autocomplete";

const Booking = ({ className }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <div className={className} id="book-now">
      <Card
        className="pattern-home-hero"
        title="Book Truck"
        bordered={className === "hero-none" ? true : false}
        style={{ width: 300, margin: "auto" }}
      >
        <div
          data-w-id="bc08baa9-9d60-24e7-8290-0ec5fa320bda"
          className="wrapper"
        >
          <Form
            form={form}
            name="basic"
            autoComplete="off"
            onFinish={(values) =>
              history.push(
                `${routes.ONBOARDING}?from=${values.from}&to=${values.to}`
              )
            }
          >
            <Typography.Text className="title">From:</Typography.Text>
            {/* <ReactGoogleAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onPlaceSelected={(place) => console.log(place)}
          />
        */}
            <Form.Item
              name="from"
              rules={[
                {
                  required: true,
                  message: "Enter your loading city!",
                },
              ]}
            >
              <Autocomplete
                placeholder="Enter your loading city"
                form={form}
                name={"from"}
              />
              {/* <Input
              placeholder="Enter your loading city"
              prefix={<Avatar src="/img/pin.png" size={20} />}
              style={{ borderRadius: 8 }}
              // ref={ref}
            /> */}
            </Form.Item>
            <Typography.Text className="title">To:</Typography.Text>
            <Form.Item
              name="to"
              rules={[
                {
                  required: true,
                  message: "Enter your unloading city!",
                },
              ]}
            >
              <Autocomplete
                placeholder="Enter your unloading city"
                form={form}
                name={"to"}
              />
              {/* <Input
              placeholder="Enter your unloading city"
              prefix={<Avatar src="/img/pin.png" size={20} />}
              style={{ borderRadius: 8 }}
            /> */}
            </Form.Item>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Typography.Text style={{ textAlign: "right" }}>
                Already have an account?{" "}
                <Typography.Text
                  onClick={() => history.push(routes.LOGIN)}
                  style={{
                    color: "#1890ff",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                >
                  Login
                </Typography.Text>
              </Typography.Text>

              <Button type="primary" htmlType="submit" block shape="round">
                Book Now
              </Button>
            </Space>
          </Form>

          {/* <Typography.Text onClick={() => history.push(routes.LOGIN)}>
          Already have an account?{" "} <Typography.Text>Login</Typography.Text>
        </Typography.Text> */}
        </div>
      </Card>
    </div>
  );
};

export default Booking;
