import React from "react";
import { getStatusColor } from "../../utils/config";

const StatusPill = ({ status, styles = {}, ...props }) => {
  return (
    <span
      {...props}
      style={{
        padding: "7px 12px",
        borderRadius: "100px",
        backgroundColor: getStatusColor(status),
        color: "white",
        ...styles,
      }}
    >
      {status}
    </span>
  );
};

export default StatusPill;
