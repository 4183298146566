/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  LogoutOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slice/auth.slice";
import { useHistory } from "react-router-dom/";
import { routes } from "../utils/routesConfig";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const { Sider, Content } = Layout;

const LayoutWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const location = useLocation();
  const pathName = location.pathname;
  const [selectedKey, setSeletedKey] = useState("orderCompleted");

  useEffect(() => {
    if (pathName) getSelectedMenuItem(pathName);
  }, [pathName]);

  const getSelectedMenuItem = (pathName) => {
    switch (pathName) {
      case routes.CREATE_ORDER:
        setSeletedKey("createOrder");
        break;
      case routes.ORDER_LIVE:
        setSeletedKey("orderLive");
        break;
      case routes.ORDER_COMPLETED:
        setSeletedKey("orderCompleted");
        break;
      case routes.ORDERS:
        setSeletedKey("orders");
        break;
      case routes.AGRI_ORDERS:
        setSeletedKey("agriOrders");
        break;
      case routes.ALL_FAVORITE_ORDERS:
        setSeletedKey("allFavoriteOrders");
        break;
      case routes.BILLINGS:
        setSeletedKey("billings");
        break;
      case "logout":
        break;
      default:
        setSeletedKey("9999");
        break;
    }
  };

  const onPressMenuItems = (e) => {
    switch (e.key) {
      case "createOrder":
        history.push(routes.CREATE_ORDER);
        break;
      case "orderLive":
        history.push(routes.ORDER_LIVE);
        break;
      case "orderCompleted":
        history.push(routes.ORDER_COMPLETED);
        break;
      case "orders":
        history.push(routes.ORDERS);
        break;
      case "agriOrders":
        history.push(routes.AGRI_ORDERS);
        break;
      case "allFavoriteOrders":
        history.push(routes.ALL_FAVORITE_ORDERS);
        break;
      case "billings":
        history.push(routes.BILLINGS);
        break;
      case "logout":
        dispatch(logout());
        break;
      default:
        break;
    }
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          className="user-info"
          style={
            collapsed
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}
          }
        >
          <div
            style={{
              minWidth: "35px",
            }}
          >
            <Avatar
              size={35}
              icon={<UserOutlined />}
              style={{ cursor: "pointer" }}
            />
          </div>
          {!collapsed ? (
            <Link to={routes.PROFILE}>
              <h5>{user.name}</h5>
            </Link>
          ) : null}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          onClick={onPressMenuItems}
          items={[
            {
              key: "createOrder",
              icon: <ShoppingCartOutlined />,
              label: "Create Order",
            },
            {
              key: "orderLive",
              icon: <ShoppingCartOutlined />,
              label: "Live Order",
            },
            {
              key: "orderCompleted",
              icon: <ShoppingCartOutlined />,
              label: "Completed Order",
            },
            {
              key: "orders",
              icon: <ShoppingCartOutlined />,
              label: "Order History",
            },
            {
              key: "agriOrders",
              icon: <ShoppingCartOutlined />,
              label: "Agri Orders",
            },
            {
              key: "allFavoriteOrders",
              icon: <ShoppingCartOutlined />,
              label: "Favorite Orders",
            },
            {
              key: "billings",
              icon: <ShoppingCartOutlined />,
              label: "Billings",
            },
            {
              key: "logout",
              icon: <LogoutOutlined />,
              label: "Logout",
            },
          ]}
        />
      </Sider>

      <Layout>
        <div
          style={{
            height: "calc(100vh - 50px)",
            overflowX: "auto",
          }}
        >
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            {children}
          </Content>
        </div>
      </Layout>
    </Layout>
  );
};
export default LayoutWrapper;
