import React from "react";

const About = () => {
  return (
    <>
      <div className="section wf-section" id="about">
        <div className="wide-container horizontal-to-vertical">
          <div className="left-side-content">
            <div className="heading-wrapper">
              <h2 className="heading-2">
                Why we have
                <br />
                millions of customers
                <br />
                every year
              </h2>
              <div className="border" />
            </div>
            <p className="paragraph wide">
              {/* India's Largest Marketplace for Intracity Logistics |
        Revolutionizing one delivery at a time */}
              Tender Truck is a new, one-of-a-kind freight matching application
              exclusively for the logistics community in India. Our tagline,
              "For the Transporters, by a Transporter," reflects our commitment
              to providing a platform that understands the pain points of the
              industry. With a team that has over 40 years of transport
              experience, we are dedicated to creating a holistic approach that
              safeguards the interest of every stakeholder in the current
              system. We aim to create a community within our transporter
              community which is not only the most reliable platform to hire
              trucks but also the easiest, and the most efficient mode for all
              their needs.
            </p>
    
            <div
              data-w-id="9736fd6a-3d19-ac36-0ea7-2ef20a014eac"
              className="homepage-about-image-wrapper"
            >
              <p style={{ fontWeight: "bold", fontSize: "30px",marginBottom:"2rem" }}>Our Cities</p>
              <img
                src="/img/map.jpg"
                loading="lazy"
                alt="Company executives"
                className="image"
              />
            
            </div>
          </div>
          <div className="right-side-content">
            <div
              data-w-id="63ea5ed9-118f-816b-d1b7-9577a3d52693"
              className="company-feature"
            >
              <div className="feature-title-number">50</div>
              <h3 className="heading-3">Years history</h3>
              <p className="paragraph feature">
                With 50 years of history, our company has established a strong
                reputation for excellence and trustworthiness in the industry.
              </p>
            </div>
            <div
              data-w-id="63ea5ed9-118f-816b-d1b7-9577a3d52693"
              className="company-feature"
            >
              <div className="feature-title-number">8M+</div>
              <h3 className="heading-3">Happy Customers</h3>
              <p className="paragraph feature">
                Our customer base of over 8 million reflects their satisfaction
                with our products/services. Join them and experience happiness
                today!
              </p>
            </div>
            <div
              data-w-id="63ea5ed9-118f-816b-d1b7-9577a3d52693"
              className="company-feature"
            >
              <div className="feature-title-number">2500+</div>
              <h3 className="heading-3">Trucks and growing</h3>
              <p className="paragraph feature">
                Our fleet of 2500+ trucks is expanding, ensuring reliable and
                timely transportation for all your needs.
              </p>
            </div>
            <img
              src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/611ce572a243c20959f9a842_Pattern_big.svg"
              loading="lazy"
              alt=""
              className="about-us-pattern"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
