/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, Form, Input, Row, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import moment from "moment";

import { toast } from "react-toastify";
import { OrderStatus, unsecuredCopyToClipboard } from "../../utils/config";
import { useEffect, useState } from "react";
import { getOrderDetails } from "../../requests/order.requests";
import { useHistory, useLocation } from "react-router-dom";
import { FLEET_CATEGORY } from "../../utils/config";
import OrderDetailsMap from "../../components/OrderDetailsMap";
import TruckCard from "../../modules/Onboarding/TruckCard";
import StatusPill from "../../modules/Common/StatusPill";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PodManager from "../../components/PodManager/PodManager";

const options = [
  {
    label: <TruckCard category={FLEET_CATEGORY.CONTAINER} />,
    value: FLEET_CATEGORY.CONTAINER,
  },
  {
    label: <TruckCard category={FLEET_CATEGORY.TAILOR_AND_PLATFORM} />,
    value: FLEET_CATEGORY.TAILOR_AND_PLATFORM,
  },
  {
    label: <TruckCard category={FLEET_CATEGORY.TRUCK} />,
    value: FLEET_CATEGORY.TRUCK,
  },
  {
    label: <TruckCard category={FLEET_CATEGORY.OTHERS} />,
    value: FLEET_CATEGORY.OTHERS,
  },
];
const ChildAgricOrderDetails = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [bidsForm] = Form.useForm();

  const [order, setOrder] = useState({});
  const location = useLocation();
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    if (location.pathname) {
      const totalPath = location.pathname.split("/");
      const orderId = totalPath[totalPath.length - 1];
      console.log(orderId);
      if (orderId) {
        _getOrderDetails(orderId);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (order) {
      setOrigin(order?.pickupLocation?.location || "");
      setDestination(order?.dropLocation?.location || "");
    }
  }, [order]);

  const _getOrderDetails = async (orderId) => {
    const response = await getOrderDetails(orderId);
    console.log("response", response);
    if (typeof response === "string") {
      toast.error(response);
    } else {
      console.log(response);
      form.setFieldsValue({
        createdBy: response.CreatedBy?.name,
        trackingId: response.trackingId,
        product: response.product,
        productDesc: response.productDesc,
        expectedTruck: response?.ExpectedTruck
          ? response.ExpectedTruck.vehicleName
          : response.expectedTruck,
        weight: response.weight,
        grossWeight: response.grossWeight,
        expectedBid: response.expectedBid,
        scheduledDate: moment(response.scheduledDate),
      });

      const formBody = {
        pod: response?.pod,
      };

      bidsForm.setFieldsValue(formBody);
      setOrder(response);
    }
  };

  useEffect(() => {
    if (order.orderId) {
      const interval = setInterval(() => {
        _getOrderDetails(order.orderId);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [order.orderId]);

  return (
    <>
      <Row align={"middle"} style={{ marginBottom: 10 }}>
        <Button
          icon={<ArrowLeftOutlined style={{ fontSize: 25 }} />}
          type="text"
          onClick={() => history.goBack()}
        />
        <Typography.Title level={2} style={{ marginBottom: 0, marginLeft: 5 }}>
          Order Details
        </Typography.Title>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form
            form={form}
            layout="horizontal"
            name="user"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{
              paddingTop: 0,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Form.Item label="Tracking ID" name="trackingId">
                    <Input disabled />
                  </Form.Item>
                  <Button
                    type="primary"
                    ghost
                    style={{
                      marginTop: 10,
                    }}
                    onClick={() => {
                      unsecuredCopyToClipboard(
                        order.trackingId,
                        "Tracking ID Copied"
                      );
                    }}
                  >
                    <CopyOutlined />
                  </Button>
                </div>
              </Col>
              <Col span={8}>
                <Form.Item label="Product" name="product">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Product Description" name="productDesc">
                  <Input.TextArea disabled />
                </Form.Item>
              </Col>

              {[OrderStatus.TRANSIT, OrderStatus.POD_ADDED].includes(
                order?.status
              ) ? (
                <>
                  <Col span={12}>
                    <Form.Item
                      label="Gross Weight (Ton)"
                      name="grossWeight"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <div
                      style={{
                        marginTop: 3,
                      }}
                    >
                      <Form.Item label="Bilty" name="bilty">
                        {order?.pod ? (
                          <a
                            href={order?.bilty}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View
                          </a>
                        ) : (
                          "Not added"
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </>
              ) : null}

              {[OrderStatus.COMPLETED, OrderStatus.POD_ADDED].includes(
                order?.status
              ) ? (
                <PodManager
                  allPods={order?.allPods || []}
                  dropLocation={order?.dropLocation}
                  otherDropLocations={order?.otherDropLocation || []}
                  orderId={order?.orderId}
                  setOrder={setOrder}
                  status={order?.status}
                />
              ) : null}

              <Col span={24}>
                <Typography.Title level={5}>Fleet Required</Typography.Title>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {order?.ExpectedTruck ? (
                    <TruckCard category={order.ExpectedTruck.category} />
                  ) : (
                    <TruckCard category={options[3].label} />
                  )}
                </div>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Expected Fleet Type"
                  name="expectedTruck"
                  style={{
                    width: "50%",
                  }}
                >
                  <Input disabled placeholder="Enter Fleet Type" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Typography.Title level={5}>Fleet Status</Typography.Title>
                <div>
                  <StatusPill status={order.addTruckStatus} />
                </div>
              </Col>
              <Col span={8}>
                <Form.Item label="Weight (Tons)" name="weight">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Price / Ton" name="expectedBid">
                  <Input
                    type="number"
                    min={0}
                    onWheel={(e) => e.currentTarget?.blur()}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Scheduled Date" name="scheduledDate">
                  <DatePicker
                    disabledDate={(current) => {
                      return (
                        current &&
                        current < moment().subtract(1, "d").endOf("day")
                      );
                    }}
                    style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <OrderDetailsMap
            origin={origin}
            setOrigin={setOrigin}
            destination={destination}
            setDestination={setDestination}
            directions={directions}
            setDirections={setDirections}
            driverLocation={order?.driverLocation}
          />
        </Col>
      </Row>
    </>
  );
};

export default ChildAgricOrderDetails;
