import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  message: "",
  type: "",
  requests: [],
};

/**** Slice ****/
export const miscSlice = createSlice({
  name: "notification",
  initialState: INITIAL_STATE,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    pushRequest: (state) => {
      state.requests.push(1);
    },
    popRequest: (state) => {
      state.requests.pop();
    },
  },
});

export const { pushRequest, popRequest } = miscSlice.actions;

export default miscSlice.reducer;
