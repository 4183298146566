import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom/";

const Navigation = () => {
  const headerRef = useRef(null);
  const sticky = headerRef.current ? headerRef.current.offsetTop : 0;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function stickyHeader() {
      const header = headerRef.current;

      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        header.classList.remove("sticky-removed");
      } else {
        header.classList.remove("sticky");
        header.classList.add("sticky-removed");
      }
    }

    window.addEventListener("scroll", stickyHeader);

    return () => {
      window.removeEventListener("scroll", stickyHeader);
    };
  }, [sticky]);
  return (
    <div
      ref={headerRef}
      data-collapse="medium"
      data-animation="default"
      data-duration={400}
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
      style={{
        willChange: "background",
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
      id="tendor-navbar"
    >
      <div className="navbar-container">
        <a
          href="/"
          aria-current="page"
          className="logo-container w-inline-block w--current"
        >
          <img
            src="/img/tender-truck.webp"
            loading="lazy"
            alt="Tender Truck logo"
            className="logo"
          />
          <img
            src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/612ca0b0101af9513a07cacd_rth_logo_dark.svg"
            loading="lazy"
            alt="Tender Truck dark logo icon"
            className="logo dark"
            style={{ willChange: "opacity", opacity: 0 }}
          />
        </a>
        <div
          className="menu-button w-nav-button"
          style={{ WebkitUserSelect: "text" }}
          aria-label="menu"
          role="button"
          tabIndex={0}
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded="false"
        >
          <img
            src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/612c952354a6a61459dbd14a_menu_light.svg"
            loading="lazy"
            alt="Menu icon"
            className="menu-icon"
          />
          <img
            src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/612ca257e66698ff089151d3_menu_dark.svg"
            loading="lazy"
            alt="menu icon dark"
            className="menu-icon dark"
            style={{}}
          />
          <img
            src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/612ca79d35b1fd199d37bce8_close.svg"
            loading="lazy"
            alt="Close menu icon"
            className="menu-icon close"
            style={{}}
            onClick={() => {
        
              setOpen(!open);
            }}
          />
        </div>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <a
            href="/"
            aria-current="page"
            className="nav-link w-nav-link w--current"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              color: "rgb(181 181 181)",
            }}
          >
            Home
          </a>
          <Link
            to="/?section=about"
            className="nav-link w-nav-link"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              color: "rgb(181 181 181)",
            }}
          >
            About
          </Link>
          <div
            data-hover="true"
            data-delay={0}
            className="w-dropdown"
            style={{}}
          >
            <Link
              to="/?section=services"
              className="nav-link w-nav-link"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
                color: "rgb(181 181 181)",
              }}
            >
              Services
            </Link>
          </div>
          <Link
            to="/career"
            className="nav-link w-nav-link"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              color: "rgb(181 181 181)",
            }}
          >
            Careers
          </Link>
          <Link
            to="/?section=contact"
            data-w-id="daff7c6d-7132-8bc1-aa21-bb0c09e6b7eb"
            className="nav-link contact w-nav-link"
          >
            Contact
          </Link>
        </nav>
      </div>
      <div
        className="w-nav-overlay"
        style={{
          display: open ? "block" : "none",
        }}
      >
        <nav
          role="navigation"
          className="nav-menu w-nav-menu"
          data-nav-menu-open=""
        >
          <a
            href="/"
            aria-current="page"
            className="nav-link w-nav-link w--current w--nav-link-open"
          >
            Home
          </a>
          <Link
            to="/?section=about"
            className="nav-link w-nav-link w--nav-link-open"
          >
            About
          </Link>
          <Link to="/career" className="nav-link w-nav-link w--nav-link-open">
            Careers
          </Link>
          <Link
            to="/?section=contact"
            data-w-id="daff7c6d-7132-8bc1-aa21-bb0c09e6b7eb"
            className="nav-link contact w-nav-link w--nav-link-open"
          >
            Contact
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
