/* eslint-disable no-undef */
/* eslint-disable no-console */
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Button, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import "./Map.css";

const center = { lat: 21.7679, lng: 78.8718 };

const libraries = ["places"];

const OrderDetailsMap = ({
  origin,
  destination,
  directions,
  setDirections,
  setOrigin,
  setDestination,
  driverLocation,
  otherPickupLocation,
  otherDropLocation,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries,
  });

  const [map, setMap] = useState(null);
  const [directionsResponseOne, setDirectionResponseOne] = useState(null);
  const [directionResponseTwo, setDirectionResponseTwo] = useState(null);
  const [directionResponseThree, setDirectionResponseThree] = useState(null);
  const originTwoRef = useRef(null);
  const destinationTwoRef = useRef(null);
  const originThreeRef = useRef(null);
  const destinationThreeRef = useRef(null);

  const originOneRef = useRef(null);
  const destinationOneRef = useRef(null);

  const renderDirectionOne = async (o, d) => {
    if (originOneRef?.current) originOneRef.current.value = o;
    if (destinationOneRef?.current) destinationOneRef.current.value = d;

    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: o,
      destination: d,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionResponseOne(results);
  };

  const renderDirectionTwo = async (o, d) => {
    if (originTwoRef?.current) originTwoRef.current.value = o;
    if (destinationTwoRef?.current) destinationTwoRef.current.value = d;

    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: o,
      destination: d,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionResponseTwo(results);
  };

  const renderDirectionThree = async (o, d) => {
    if (originThreeRef?.current) originThreeRef.current.value = o;
    if (destinationThreeRef?.current) destinationThreeRef.current.value = d;

    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: o,
      destination: d,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionResponseThree(results);
  };

  useEffect(() => {
    if (origin && destination && isLoaded) {
      renderDirectionOne(origin, destination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, destination, isLoaded]);

  useEffect(() => {
    if (otherPickupLocation?.length && otherDropLocation?.length && isLoaded) {
      if (otherPickupLocation.length === 2 && otherDropLocation.length === 2) {
        renderDirectionTwo(
          otherPickupLocation[0].location,
          otherDropLocation[0].location
        );
        renderDirectionThree(
          otherPickupLocation[1].location,
          otherDropLocation[1].location
        );
      } else if (
        otherPickupLocation.length === 1 &&
        otherDropLocation.length === 1
      ) {
        renderDirectionTwo(
          otherPickupLocation[0].location,
          otherDropLocation[0].location
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherPickupLocation, otherDropLocation, isLoaded]);

  if (!isLoaded) return <Spin />;

  return (
    <div>
      <GoogleMap
        center={center}
        zoom={5}
        mapContainerStyle={{ width: "100%", height: "500px" }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
        }}
        onLoad={(map) => setMap(map)}
      >
        {directionsResponseOne?.routes?.length ? (
          directionsResponseOne.routes[0]?.legs.map((leg) => (
            <>
              {/* Render origin marker */}
              <Marker
                position={{
                  lat: leg?.start_location?.lat(),
                  lng: leg?.start_location?.lng(),
                }}
                label={{
                  text: "O",
                  color: "white",
                }}
              />
              {driverLocation ? (
                <Marker
                  position={{
                    lat: +driverLocation?.lat,
                    lng: +driverLocation?.lng,
                  }}
                  icon={{
                    url: "https://tendertruck-files-prod.s3.ap-south-1.amazonaws.com/1698649523319_1698649523319_truckicon.png",
                    scaledSize: new window.google.maps.Size(65, 65),
                  }}
                />
              ) : null}

              {/* Render destination marker */}
              <Marker
                position={{
                  lat: leg?.end_location?.lat(),
                  lng: leg?.end_location?.lng(),
                }}
                label={{
                  text: "D",
                  color: "white",
                }}
              />
            </>
          ))
        ) : (
          <></>
        )}

        {directionsResponseOne?.routes?.length ? (
          <Polyline
            path={directionsResponseOne.routes[0]?.overview_path}
            options={{
              strokeColor: "blue",
              strokeOpacity: 0.8,
              strokeWeight: 4,
            }}
          />
        ) : (
          <></>
        )}

        {directionResponseTwo?.routes?.length ? (
          directionResponseTwo.routes[0]?.legs.map((leg) => (
            <>
              {/* Render origin marker */}
              <Marker
                key="4"
                position={{
                  lat: leg?.start_location?.lat(),
                  lng: leg?.start_location?.lng(),
                }}
                label={{
                  text: "P2",
                  color: "white",
                }}
              />
              {/* Render destination marker */}
              <Marker
                key="5"
                position={{
                  lat: leg?.end_location?.lat(),
                  lng: leg?.end_location?.lng(),
                }}
                label={{
                  text: "D2",
                  color: "white",
                }}
              />
            </>
          ))
        ) : (
          <></>
        )}

        {directionResponseTwo?.routes?.length ? (
          <Polyline
            path={directionResponseTwo.routes[0]?.overview_path}
            options={{
              strokeColor: "blue",
              strokeOpacity: 0.8,
              strokeWeight: 4,
            }}
          />
        ) : (
          <></>
        )}

        {directionResponseThree?.routes?.length ? (
          directionResponseThree.routes[0]?.legs.map((leg) => (
            <>
              {/* Render origin marker */}
              <Marker
                key="4"
                position={{
                  lat: leg?.start_location?.lat(),
                  lng: leg?.start_location?.lng(),
                }}
                label={{
                  text: "P3",
                  color: "white",
                }}
              />
              {/* Render destination marker */}
              <Marker
                key="5"
                position={{
                  lat: leg?.end_location?.lat(),
                  lng: leg?.end_location?.lng(),
                }}
                label={{
                  text: "D3",
                  color: "white",
                }}
              />
            </>
          ))
        ) : (
          <></>
        )}

        {directionResponseThree?.routes?.length ? (
          <Polyline
            path={directionResponseThree.routes[0]?.overview_path}
            options={{
              strokeColor: "blue",
              strokeOpacity: 0.8,
              strokeWeight: 4,
            }}
          />
        ) : (
          <></>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              map.panTo(center);
              map.setZoom(5);
            }}
            type="primary"
            style={{
              marginRight: "10px",
            }}
          >
            Center
          </Button>
        </div>
      </GoogleMap>
    </div>
  );
};

export default OrderDetailsMap;
