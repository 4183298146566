import { toast } from "react-toastify";
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
export const FLEET_CATEGORY = Object.freeze({
  CONTAINER: "CONTAINER",
  OTHERS: "OTHERS",
  TAILOR_AND_PLATFORM: "TAILOR & PLATFORM",
  TRUCK: "TRUCK",
  DALA_BODY_TRAILER: "DALA BODY TRAILER",
});

export const AllStatus = {
  APPROVED: "APPROVED",
  SUCCESS: "SUCCESS",
  VERIFIED: "VERIFIED",
  IN_PROGRESS: "IN PROGRESS",
  ERROR: "ERROR",
  CREATED: "CREATED",
  BIDDING: "BIDDING",
  BID_PLACED: "BID PLACED",
  OPEN: "OPEN",
  CONFIRMED: "CONFIRMED",
  FLEET_ADDED: "FLEET ADDED",
  EWAY_ADDED: "EWAY ADDED",
  TRANSIT: "TRANSIT",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  ALLOCATED: "ALLOCATED",
  NOT_ALLOCATED: "NOT ALLOCATED",
  CHANGE_REQUESTED: "CHANGE REQUESTED",
  CHANGE_ALLOWED: "CHANGE ALLOWED",
  VOID: "VOID",
  REOPENED: "REOPENED",
  INVALID: "INVALID",
  CLOSED: "CLOSED",
};

export const ORDER_TYPES = {
  SPOT: "SPOT",
  T1: "T-1",
  AGRI: "AGRI",
};

export const currFormat = (value, currency = "INR") => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency,
  }).format(value);
};

export const calculateAdditionPercentageAmount = (amount, percentage) => {
  if (!amount) return 0;
  const target = amount * (percentage / 100);
  return Number(amount) + Number(target);
};

export const AllStatusColors = {
  APPROVED: "#6fcf97",
  VERIFIED: "#6fcf97",
  SUCCESS: "#6fcf97",
  IN_PROGRESS: "#ffcc4d",
  ERROR: "#f4516ce6",
  CREATED: "#92A8D1",
  BIDDING: "#F7CAC9",
  CONFIRMED: "#88B04B",
  FLEET_ADDED: "#34568B",
  EWAY_ADDED: "#6B5B95",
  TRANSIT: "#ffcc4d",
  COMPLETED: "#6fcf97",
  CANCELED: "#f4516ce6",
  REJECTED: "#f4516ce6",
  PENDING: "#ffcc4d",
  ALLOCATED: "#6fcf97",
  NOT_ALLOCATED: "#f4516ce6",
  CHANGE_REQUESTED: "#ffcc4d",
  CHANGE_ALLOWED: "#34568B",
  VOID: "#6B5B95",
  REOPENED: "#6fcf97",
  INVALID: "#34568B",
};
export const getStatusColor = (status) => {
  switch (status) {
    case AllStatus.APPROVED:
      return AllStatusColors.APPROVED;
    case AllStatus.VERIFIED:
      return AllStatusColors.VERIFIED;
    case AllStatus.SUCCESS:
      return AllStatusColors.SUCCESS;
    case AllStatus.IN_PROGRESS:
      return AllStatusColors.IN_PROGRESS;
    case AllStatus.ERROR:
      return AllStatusColors.ERROR;
    case AllStatus.CREATED:
      return AllStatusColors.CREATED;
    case AllStatus.BIDDING:
    case AllStatus.OPEN:
      return AllStatusColors.BIDDING;
    case AllStatus.CONFIRMED:
      return AllStatusColors.CONFIRMED;
    case AllStatus.FLEET_ADDED:
      return AllStatusColors.FLEET_ADDED;
    case AllStatus.EWAY_ADDED:
      return AllStatusColors.EWAY_ADDED;
    case AllStatus.TRANSIT:
      return AllStatusColors.TRANSIT;
    case AllStatus.COMPLETED:
      return AllStatusColors.COMPLETED;
    case AllStatus.CANCELED:
    case AllStatus.CLOSED:
      return AllStatusColors.CANCELED;
    case AllStatus.NOT_ALLOCATED:
      return AllStatusColors.NOT_ALLOCATED;
    case AllStatus.ALLOCATED:
      return AllStatusColors.ALLOCATED;
    case AllStatus.CHANGE_REQUESTED:
      return AllStatusColors.CHANGE_REQUESTED;
    case AllStatus.CHANGE_ALLOWED:
      return AllStatusColors.CHANGE_ALLOWED;
    case AllStatus.VOID:
      return AllStatusColors.VOID;
    case AllStatus.REOPENED:
      return AllStatusColors.REOPENED;
    case AllStatus.INVALID:
      return AllStatusColors.INVALID;
    default:
      return AllStatusColors.IN_PROGRESS;
  }
};
export const unsecuredCopyToClipboard = (text, message) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
    toast.success({
      message,
    });
  } catch (err) {
    toast.error({
      message: "Unable to copy",
    });
  }
  document.body.removeChild(textArea);
};

export const GROUP_ORDER_TYPES = {
  PARENT: "PARENT",
  CHILD: "CHILD",
};

export const OrderStatus = {
  BIDDING: "BIDDING", //1h
  BID_PLACED: "BID PLACED",
  CONFIRMED: "CONFIRMED", //30min
  FLEET_ADDED: "FLEET ADDED", //30min
  EWAY_ADDED: "EWAY ADDED",
  TRANSIT: "TRANSIT",
  COMPLETED: "COMPLETED",
  POD_ADDED: "POD ADDED",
  VOID: "VOID",
  REOPENED: "REOPENED",
  INVALID: "INVALID",
  CANCELED: "CANCELED",
  TRUCK_ADDED: "TRUCK ADDED", // OBSOLETE
  EXPIRED: "EXPIRED",

  OPEN: "OPEN",
  CLOSED: "CLOSED",
};

export function getFileNameFromURL(url) {
  if (!url) return;
  // Match the file name at the end of the URL using regular expression
  // eslint-disable-next-line no-useless-escape
  const fileNameRegex = /[^/\\&?#=]+\.(\w{3,4})(?=([\?&].*$|$))/;
  const matches = url.match(fileNameRegex);

  if (matches && matches.length > 0) {
    return matches[0];
  } else {
    // If no match found, return null or an appropriate value as per your requirement
    return null;
  }
}

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const getNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      return true;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};

export const getNotificationToken = async () => {
  const token = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  });
  return token;
};

export const OTP_STATUS = {
  CREATED: "CREATED",
  SENT: "SENT",
  VERIFIED: "VERIFIED",
};

export const handleDownloadClick = async (url) => {
  const fileUrl = url; // Replace with the actual file URL

  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = getFileNameFromURL(url); // Customize the downloaded file name

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

export const TIME_DIFF = 30;

export const dateFormat = "DD/MM/YYYY";
export const timeFormat = "HH:mm:ss";

export const dateTimeFormat = "DD/MM/YYYY HH:mm:ss";

export const errorMsg = (msg, type = "Input") => {
  let error = "";
  switch (type) {
    case "Input":
      error = "Please enter the " + msg;
      break;
    case "Select":
      error = "Please select the " + msg;
      break;
    default:
      break;
  }
  return error;
};

export const OrderOtpStatus = {
  CREATED: "CREATED",
  SENT: "SENT",
  VERIFIED: "VERIFIED",
};

export const OrderLoadingDocStatus = {
  CREATED: "CREATED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  SUCCESS: "SUCCESS",
};

export const POD_STATUS = {
  CREATED: "CREATED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  SUCCESS: "SUCCESS",
};
