export const routes = Object.freeze({
  LOGIN: "/login",
  ONBOARDING: "/onboarding",
  HOME: "/",
  ORDERS: "/orders",
  ORDER_DETAILS: "/order/details/:id",
  AGRIC_ORDER_DETAILS: "/order/agri/details/:id",
  ORDER_COMPLETED: "/order/completed",
  ORDER_LIVE: "/order/live",
  AGRI_ORDERS: "/order/agri",
  PROFILE: "/profile",
  CREATE_ORDER: "/order/create",
  BILLINGS: "/billings",
  ALL_FAVORITE_ORDERS: "/order/favorite",
  LOCATION_SHARE: "/l/:trackingId",
  LOAD: "/load/:id",
});
