import { useEffect, useState } from "react";
import { Select } from "antd";

import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const Autocomplete = ({ placeholder, form, name }) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isPlacePredictionsLoading) {
      setData(
        placePredictions.map((item) => ({
          label: item.description,
          value: item.description,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlacePredictionsLoading]);

  const handleSearch = (val) => {
    getPlacePredictions({ input: val });
  };

  const selectOption = (val) => {
    form.setFieldsValue({
      [name]: val,
    });
  };
  return (
    <Select
      showSearch
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={selectOption}
      notFoundContent={null}
      options={data}
    />
  );
};
