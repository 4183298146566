import React from "react";
import Booking from "../../modules/Home/Booking";

const HeroSection = () => {
  // const scrollToSection = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   console.log(section);
  //   if (section) {
  //     const offset = section.offsetTop;
  //     console.log(offset);
  //     window.scrollTo({ top: offset, behavior: "smooth" });
  //   }
  // };
  return (
    <div className="hero-homepage wf-section">
      <div className="hero-image-container">
        <img
          src="/img/landingImg.jpg"
          loading="lazy"
          sizes="100vw"
          alt="Homepage hero  with 2 trucks"
          className="hero-image position-left"
        />
      </div>
      <div className="wide-container">
        <div className="hero-content">
          <div className="main-title-container">
            <div className="above-title-text">
              For the Transporters, By A Transporter
            </div>
            <div className="title-container">
              <div className="border" />
              <div className="heading-1-wrapper">
                <h1 className="heading-1">
                  India's largest <br />
                </h1>
                <h1 className="heading-1">
                  <span className="orange-span">trucking</span> platform
                </h1>
              </div>
            </div>
          </div>
          <p
            data-w-id="fd9346b7-ea91-49cd-514c-f9e2440210f5"
            className="paragraph"
          >
            Tender Truck is a new, one-of-a-kind freight matching application
            exclusively for the logistics community in India.
          </p>
        </div>
      </div>
      <br className="hero-none" />
      <br className="hero-none" />

      {/* <a
        class="button-type-1 w-button hero-none"
        style={{ color: "#fff", marginTop: "0px" }}
        rel="noopener noreferrer"
        href="#book-now"
        onClick={() => scrollToSection("book-now")}
      >
        Book Now
      </a> */}

      <Booking className={"hero-none-mobile"} />
    </div>
  );
};

export default HeroSection;
