import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ScrollToSection = () => {
  const scrollRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const section = params.get("section");

  useEffect(() => {
    if (section) {
      const targetElement = document.getElementById(section);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [section]);

  return <div ref={scrollRef} />;
};

export default ScrollToSection;
