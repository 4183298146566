import axios from "axios";
import { popRequest, pushRequest } from "../slice/misc.slice";
import { persistor, store } from "../store";

export default class API {
  constructor(options) {
    this.axiosInstance = axios.create({
      baseURL: options.baseUrl,
    });
    this.axiosInstance.interceptors.request.use(
      function (config) {
        persistor.dispatch(pushRequest());
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      function (response) {
        persistor.dispatch(popRequest());
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
    );
  }

  get(endpoint, header) {
    return this.httpRequest("GET", endpoint, header);
  }

  post(endpoint, params, header) {
    return this.httpRequest("POST", endpoint, params, header);
  }

  update(endpoint, params, header) {
    return this.httpRequest("PUT", endpoint, params, header);
  }

  postForm(endpoint, params, header) {
    return this.httpRequest("POST", endpoint, params, {
      ...header,
      "Content-Type": "multipart/form-data",
    });
  }

  updateForm(endpoint, params, header) {
    return this.httpRequest("PUT", endpoint, params, {
      ...header,
      "Content-Type": "multipart/form-data",
    });
  }

  async httpRequest(method, url, params, header = null) {
    let state = store.getState();
    let clientToken = state.auth.clientToken;
    return new Promise((resolve, reject) => {
      let options;
      if (method === "GET") {
        options = {
          url: url,
          headers: {
            authorization: clientToken ? `Bearer ${clientToken}` : null,
            "Content-Type": "application/json",
            ...header,
          },
          method: method,
        };
      } else {
        options = {
          url: url,
          headers: header
            ? header
            : {
                authorization: clientToken ? `Bearer ${clientToken}` : null,
                "Content-Type": "application/json",
                ...header,
              },
          method: method,
          data: params,
        };
      }
      this.axiosInstance
        .request(options)
        .then((response) => {
          resolve({
            status: response.status,
            ...response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
