/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";

const Testimonials = () => {
  const testimContentRef = useRef([]);
  const testimSpeed = 2000;
  let currentSlide = 0;
  let currentActive = 0;
  let testimTimer;

  useEffect(() => {
    const testimContent = testimContentRef.current;

    // Testim Script
    function playSlide(slide) {
      for (let k = 0; k < testimContent.length; k++) {
        testimContent[k].classList.remove("active");
        testimContent[k].classList.remove("inactive");
      }

      if (slide < 0) {
        slide = currentSlide = testimContent.length - 1;
      }

      if (slide > testimContent.length - 1) {
        slide = currentSlide = 0;
      }

      if (currentActive !== currentSlide) {
        testimContent[currentActive].classList.add("inactive");
      }
      testimContent[slide].classList.add("active");

      currentActive = currentSlide;

      clearTimeout(testimTimer);
      testimTimer = setTimeout(() => {
        playSlide((currentSlide += 1));
      }, testimSpeed);
    }

    playSlide(currentSlide);

    return () => {
      clearTimeout(testimTimer);
    };
  }, []);

  return (
    <div className="section wf-section">
      <div className="wide-container">
        <div className="heading-wrapper">
          <h2 className="heading-2">
            Read our latest <br />
            Testimonials.
            <br />
          </h2>
          <div
            className="border"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          />
        </div>

        {/* testimonial */}
        <div className="container">
          <section id="testim" className="testim">
            <div className="testim-cover">
              <div className="wrap">
                <div
                  id="testim-content"
                  className="cont"
                  ref={(el) =>
                    (testimContentRef.current = Array.from(el?.children || []))
                  }
                >
                  <div className="active">
                    <div className="img">
                      <img
                        width={100}
                        height={100}
                        src="https://img.icons8.com/ios-filled/100/user-male-circle.png"
                        alt="user-male-circle"
                      />
                    </div>
                    <h2>Dushyant Arya (Indo-Arya Logistics)</h2>
                    <p>
                      I can never trust my booking manager when we are hiring
                      market trucks. This has been a huge pain point for me and
                      fellow transporters. Tender Truck provides the exact
                      solution that we required, and our managers are also happy
                      using their app.
                    </p>
                  </div>
                  <div>
                    <div className="img">
                      <img
                        width={100}
                        height={100}
                        src="https://img.icons8.com/ios-filled/100/user-male-circle.png"
                        alt="user-male-circle"
                      />
                    </div>
                    <h2>Nitin Mahipal (Mega Freight Carrier)</h2>
                    <p>
                      This comment reflects a positive assessment of a
                      particular application, which is seen as having great
                      potential for addressing a specific issue that is relevant
                      to a particular industry or market segment. The comment
                      expresses support for the app's developers and their
                      efforts, and suggests that the app may be well-received by
                      users or customers who are facing similar challenges or
                      obstacles.
                    </p>
                  </div>
                  <div>
                    <div className="img">
                      <img
                        width={100}
                        height={100}
                        src="https://img.icons8.com/ios-filled/100/user-male-circle.png"
                        alt="user-male-circle"
                      />
                    </div>
                    <h2>Ramandeep Singh (Okara Transport)</h2>
                    <p>
                      A very honest team, have known them since 40 years and
                      have full trust in them. Trust is what was required in the
                      fleet hiring system.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <img
        src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/611ce572a243c20959f9a842_Pattern_big.svg"
        loading="lazy"
        alt=""
        className="homepage-blogs-pattern"
      />
    </div>
  );
};

export default Testimonials;
