import Api from "../services";

export const createOnbaordingCustomer = async (payload) => {
  try {
    const response = await Api.post("/customer/create", payload);
    return response.code ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createContact = async (payload) => {
  try {
    const response = await Api.post("/contact/create", payload);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};
