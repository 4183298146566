export const getMergedLocations = (location, locations = []) => {
  const merged = [location, ...(locations || [])].filter(Boolean);
  if (!merged.length) return "";
  const mapLocations = merged.map((loc) => loc?.location).filter(Boolean);
  // remove , India from last part of every string in the array
  const cleanedLocations = mapLocations.map((loc) => {
    return loc.replace(/, India$/, "").split(/\s*,\s*/);
  });

  return cleanedLocations.join(" + ");
};
