import Api from "../services";
import { store } from "../store";

export const registerUsingOnlyPhone = async (payload) => {
  try {
    const response = await Api.post("/user/send-registration-otp", payload);
    return response.code ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const verifyOTP = async (payload) => {
  try {
    const response = await Api.post("/user/verify-registration-otp", payload);
    return response.code ? { message: response.message } : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};
export const login = async (payload) => {
  try {
    const response = await Api.post("/user/login", payload);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateCustomer = async (payload) => {
  try {
    const { userId } = store.getState().auth.user;
    const response = await Api.update(`/user/${userId}`, payload);
    return response.code ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};
