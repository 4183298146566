import React from "react";

const Services = () => {
  return (
    <div
      className="section wf-section"
      id="services"
      style={{ background: "#f5f5f5" }}
    >
      <div className="wide-container horizontal-to-vertical">
        <div className="left-side-content">
          <div className="heading-wrapper">
            <h2 className="heading-2">Our Products</h2>
            <div
              className="border"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1)                  rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            />
          </div>
        </div>
      </div>
      <br />
      <div className="hidden">
        <div
          className="wide-container horizontal-to-vertical"
          style={{ alignItems: "flex-start" }}
        >
          <div className="left-side-content">
            <h2>Customer App</h2>
            <br />
            <p style={{ lineHeight: "30px" }}>
              Tender Truck provides fleet owners and truckers with an
              opportunity to increase their efficiency and reduce their
              dependence on intermediaries. With our platform, fleet owners and
              truckers can get maximum loads and return loads, which enables
              them to optimize their operations and improve their revenue.
              <br />
              <br />
              We also provide instant payments and Munshiana to fleet owners to
              help them grow their businesses.
              <br />
              <br />
              Tender Truck is a platform that is committed to the welfare of
              fleet owners and truckers. We understand their needs and provide
              them with a community that is supportive and inclusive.
              <br />
              <br />
              What is Discounting? – If you wish to receive the cash instantly
              without waiting for the transporter, you can get the bill
              discounted at 1%.
            </p>
            <br />
            <p>Download on Google Play</p>
            <br />
            <div style={{ display: "flex" }}>
              <img src="img/googlePlay.svg" alt="" />
            </div>
          </div>
          <div
            className="right-side-content"
            style={{ border: "none", height: "auto" }}
          >
            <img
              src="img/fleet-owner.webp"
              alt=""
              className="img-fluid"
              style={{ borderRadius: "57px" }}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div
        className="wide-container horizontal-to-vertical"
        style={{ alignItems: "flex-start" }}
      >
        <div
          className="right-side-content"
          style={{ border: "none", height: "auto" }}
        >
          <img
            src="img/supplier.png"
            alt=""
            className="img-fluid"
            style={{ borderRadius: "57px" }}
          />
        </div>
        <div className="left-side-content" style={{ margin: "20px 40px" }}>
          <h2>Supplier App</h2>
          <br />
          <p style={{ lineHeight: "30px" }}>
            Customer App At Tender Truck, we understand that transparency and
            efficiency are crucial in the logistics industry.
            <br />
            <br />
            Our platform provides transporters and logistics service providers
            with a transparent and efficient solution for all their secondary
            hiring needs.
            <br />
            <br />
            With our community approach, you can connect with reliable and
            honest fleet owners or truckers, expanding your business and
            creating new opportunities.
          </p>
          <br />
          <p>Download on Google Play</p>
          <br />
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.tendertruck.supplier",
                "_blank"
              );
            }}
          >
            <img src="img/googlePlay.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
