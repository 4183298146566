import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Footer = () => {
  return (
    <footer id="footer" className="footer wf-section">
      <div className="wide-container for-footer">
        <div className="footer-links-container">
          <div className="footer-column">
            <p className="paragraph footer-bold">Company</p>
            <a href="/" aria-current="page" className="footer-link w--current">
              Home
            </a>

            <Link to="/?section=about" className="footer-link">
              About
            </Link>
            <Link to="/career" className="footer-link">
              Careers
            </Link>

            <a
              href="/privacypolicy"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Privacy Policy
            </a>
          </div>
          <div className="footer-column">
            <p className="paragraph footer-bold">Products</p>
            <div className="w-dyn-list">
              <div role="list" className="w-dyn-items">
                <div role="listitem" className="w-dyn-item">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tendertruck.supplier"
                    className="footer-link w-inline-block"
                  >
                    <div>Supplier App</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-column">
            <p className=" paragraph footer-bold">Tender Truck</p>
            <p className="footer-link">Where you can find us</p>
            {/* <a class="footer-link"> GST- 07AAICT0682L1ZY</a> */}
            <a
              className="footer-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/Mehrauli-Gurgaon+Rd,+Ghitorni,+New+Delhi,+Delhi/@28.4933969,77.1378051,15.79z/data=!4m10!1m2!2m1!1sShreya+Building,+Kh+No.+415,+Ghitorni,+MG+++++++++++++++Road,!3m6!1s0x390d1e15b7e41765:0x53f70bb5f0dca4ee!8m2!3d28.4915517!4d77.1433654!15sCj1TaHJleWEgQnVpbGRpbmcsIEtoIE5vLiA0MTUsIEdoaXRvcm5pLCBNRyAgICAgICAgICAgICAgIFJvYWQskgEFcm91dGXgAQA!16s%2Fg%2F1235g0tjw"
            >
              Address- 2nd Floor, Shreya Building, Kh No. 415, Ghitorni, MG
              Road,
            </a>
            <a
              className="footer-link"
              rel="noreferrer"
              href="https://www.google.com/maps/place/Mehrauli-Gurgaon+Rd,+Ghitorni,+New+Delhi,+Delhi/@28.4933969,77.1378051,15.79z/data=!4m10!1m2!2m1!1sShreya+Building,+Kh+No.+415,+Ghitorni,+MG+++++++++++++++Road,!3m6!1s0x390d1e15b7e41765:0x53f70bb5f0dca4ee!8m2!3d28.4915517!4d77.1433654!15sCj1TaHJleWEgQnVpbGRpbmcsIEtoIE5vLiA0MTUsIEdoaXRvcm5pLCBNRyAgICAgICAgICAgICAgIFJvYWQskgEFcm91dGXgAQA!16s%2Fg%2F1235g0tjw"
              target="_blank"
            >
              New Delhi, Pin-110030
            </a>
          </div>
          <div className="footer-column">
            <p className="paragraph footer-bold">Socials</p>
            <div className="wrapper horizontal">
              <a
                href="https://www.instagram.com/tender_truck/"
                target="_blank"
                rel="noreferrer"
                className="social-link-block w-inline-block"
              >
                <img
                  src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/611e1ff25446a73e2aa8a66a_instagram.svg"
                  loading="lazy"
                  alt="Instagram icon"
                  className="social-icon image-2"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/tender-truck/"
                target="_blank"
                rel="noreferrer"
                className="social-link-block w-inline-block"
              >
                <img
                  src="https://assets.website-files.com/611ca37bcc1faceb3e0e95c1/611e1ff234ac221d05321d1b_linkedin.svg"
                  loading="lazy"
                  alt="Linkedin icon"
                  className="social-icon image-2"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
