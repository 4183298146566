import { Typography, Image } from "antd";
// import Image from "../Image/Image";
// import "./categoryTruck.css";
import { FLEET_CATEGORY } from "../../utils/config";

const TruckCard = ({ category }) => {
  const getImage = (category) => {
    switch (category) {
      case FLEET_CATEGORY.CONTAINER:
        return "/img/containerTruck.svg";
      case FLEET_CATEGORY.OTHERS:
        return "/img/otherTruck.svg";
      case FLEET_CATEGORY.TAILOR_AND_PLATFORM:
        return "/img/tailorTruck.svg";
      case FLEET_CATEGORY.TRUCK:
        return "/img/truckCategory.svg";
      case FLEET_CATEGORY.DALA_BODY_TRAILER:
        return "/img/dalaTruck.svg";
      default:
        return null;
    }
  };
  return (
    <div className={`category-wrap`}>
      {getImage(category) && (
        <Image
          preview={false}
          src={getImage(category)}
          style={{
            width: "100px",
            height: "80px",
          }}
        />
      )}
      <Typography.Text className="category-text">{category}</Typography.Text>
    </div>
  );
};

export default TruckCard;
