import React from "react";
import Navigation from "../../components/Navigation";
import About from "../../modules/Home/About";
import Services from "../../modules/Home/Services";
import Contact from "../../modules/Home/Contact";
import Footer from "../../components/Footer";
import HeroSection from "../../modules/Common/HeroSection";
import Testimonials from "../../modules/Home/Testimonials";
import Booking from "../../modules/Home/Booking";

const Home = () => {
  return (
    <>
      <div>
        <Navigation />
        <HeroSection />
        <br />
        <Booking className={"hero-none"} />
        <About />
        <Services />
        <Testimonials />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;
