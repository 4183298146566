import { useEffect } from "react";

const LoadRedirect = () => {
  useEffect(() => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.tendertruck.supplier&hl=en"; // Replace with the actual Play Store link
  }, []);

  return (
    <div>
      <h1>Redirecting to Play Store...</h1>
    </div>
  );
};

export default LoadRedirect;
