import React, { useState } from "react";
import { toast } from "react-toastify";
import { createContact } from "../../requests/customer.requests";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !formData.name ||
      !formData.phone ||
      !formData.email ||
      !formData.message
    ) {
      toast.error("Please fill in all fields.");
      return;
    }
    // If validation passes, you can submit the form or perform other actions
    const response = await createContact(formData);
    if (typeof response === "string") {
      toast.error(response);
    } else {
      toast.success("Query submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  };
  return (
    <div
      className="section wf-section"
      id="contact"
      style={{ background: "#f5f5f5" }}
    >
      <div className="wide-container">
        <form
          className="contact-form"
          id="tendor-contact"
          onSubmit={handleSubmit}
        >
          <div className="heading-wrapper">
            <h2 className="heading-2">
              Contact Us.
              <br />
            </h2>
            <div className="border" />
          </div>
          <br />
          <div>
            <label htmlFor="name" className="label">
              Name
            </label>
            <input
              type="text"
              placeholder="Name"
              className="input"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          {/* <div>
            <label htmlFor="email" className="label">
              Email
            </label>
            <input
              type="text"
              placeholder="Email"
              className="input"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div> */}
          <div>
            <label htmlFor="phone" className="label">
              Phone
            </label>
            <input
              type="text"
              placeholder="Phone Number"
              className="input"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="message" className="label">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              className="input"
              style={{ height: "100px" }}
              defaultValue={""}
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <div>
            <button
              className="button-type-1 w-button"
              style={{
                color: "#fff",
                marginTop: "10px",
                borderRadius: "15px",
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
