/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./App.css";
import AppRoutes from "./router";

import { onMessage } from "@firebase/messaging";
import { messaging } from "./firebase";
import { notification } from "antd";

function App() {
  // const [api, contextHolder] = notification.useNotification();

  // useEffect(() => {
  //   onMessage(messaging, (payload) => {
  //     console.log(payload);
  //     api.open({
  //       message: payload.data.title,
  //       description: payload.data.body,
  //       duration: 0,
  //     });
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {/* {contextHolder} */}
      <AppRoutes />
    </>
  );
}

export default App;
