import { ArrowDownOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { getMergedLocations } from "./../../utils/order";

const OrderLocation = ({ order, trackingId, bold }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-1">
      {trackingId && (
        <Typography.Text className="font-bold">{trackingId}</Typography.Text>
      )}
      <div className="flex flex-col items-center justify-center gap-y-1">
        <Typography.Text className={`text-center ${bold && "font-bold"}`}>
          {getMergedLocations(
            order?.pickupLocation,
            order?.otherPickupLocation
          )}
        </Typography.Text>
        <ArrowDownOutlined />
        <Typography.Text className={`text-center ${bold && "font-bold"}`}>
          {getMergedLocations(order?.dropLocation, order?.otherDropLocation)}
        </Typography.Text>
      </div>
    </div>
  );
};

export default OrderLocation;
