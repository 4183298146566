import { PushpinOutlined } from "@ant-design/icons";
import { Button, Image, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOrderLocationDetails } from "../../requests/order.requests";
import OrderLocation from "../../components/OrderLocation/OrderLocation";

const SharedLocationPage = () => {
  const { trackingId } = useParams();
  const [order, setOrder] = useState();
  const [pickupLocations, setPickupLocations] = useState([]);
  const [dropLocations, setDropLocations] = useState([]);
  console.log("1");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getOrder = async () => {
    const data = await getOrderLocationDetails(trackingId);
    console.log(data);
    if (typeof data === "string") {
      toast.error(data);
    } else {
      setOrder(data);
      const locations = data?.sharedLocations || [];
      setPickupLocations(
        locations?.filter((location) => location.label.includes("P"))
      );
      setDropLocations(
        locations?.filter((location) => location.label.includes("D"))
      );
    }
  };

  useEffect(() => {
    if (trackingId) getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  return (
    <div className="md:w-96 mx-3 md:mx-auto">
      <div>
        <div className="flex items-center space-x-2 pt-3 mb-2">
          <Image
            src="/img/tender-truck.png"
            width="100px"
            style={{
              margin: "0px",
            }}
            preview={false}
          />
          <Typography.Title level={4} className="font-bold text-start">
            Shared Location
          </Typography.Title>
        </div>
        <Typography.Text className="ml-2 font-bold mb-6" copyable>
          TripId: <span className="text-blue-500">{trackingId}</span>
        </Typography.Text>
        <div className="shadow-lg px-2 py-3 border border-solid border-[#030645] rounded-lg mt-2">
          <OrderLocation order={order ? order : {}} bold />
        </div>
        <div className="my-2 space-y-2">
          <Typography.Text className="ml-4 font-bold mb-2">
            <PushpinOutlined /> Loading Location Details
          </Typography.Text>
          {pickupLocations.map((item, index) => (
            <div key={index} className="rounded-lg py-3 px-6 bg-[#030645]">
              <Typography.Text className="block text-[#a09e9ed8]">
                Loading Location ({item.label})
              </Typography.Text>
              <Typography.Text className="block text-white font-semibold">
                {item.address}
              </Typography.Text>
              {item?.googleMapLink && (
                <Button
                  size="small"
                  type="link"
                  className="-ml-2"
                  onClick={() => {
                    window.open(item.googleMapLink, "_blank");
                  }}
                >
                  Open in Map
                </Button>
              )}
            </div>
          ))}
        </div>
        <div className="my-6 space-y-2">
          <Typography.Text className="ml-4 font-bold">
            <PushpinOutlined /> Unloading Location Details
          </Typography.Text>
          {dropLocations.map((item, index) => (
            <div key={index} className="rounded-lg py-3 px-6 bg-[#030645]">
              <Typography.Text className="block text-[#a09e9ed8]">
                Unloading Location ({item.label})
              </Typography.Text>
              <Typography.Text className="block text-white font-semibold">
                {item.address}
              </Typography.Text>
              {item?.googleMapLink && (
                <Button
                  size="small"
                  type="link"
                  className="-ml-2"
                  onClick={() => {
                    window.open(item.googleMapLink, "_blank");
                  }}
                >
                  Open in Map
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SharedLocationPage;
