import { Button, Col, Collapse, Popconfirm, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  changePodStatus,
  verifyOrRejectAllPods,
} from "../../requests/order.requests";
import { toast } from "react-toastify";
import { AllStatus, OrderStatus, POD_STATUS } from "../../utils/config";
import StatusPill from "../../modules/Common/StatusPill";

const PodManager = ({
  orderId,
  setOrder,
  dropLocation,
  allPods,
  otherDropLocations,
  status,
}) => {
  const handleChangePodStatus = async (podStatus, label) => {
    try {
      const body = {
        status: podStatus,
        label,
        orderId,
      };

      const response = await changePodStatus(body);
      if (typeof response === "string") {
        toast.error(response);
      } else {
        setOrder((prevOrder) => ({
          ...prevOrder,
          status: response.status,
          allPods: response.allPods,
        }));
        toast.success(`POD (${label}) status changed successfully!`);
      }
    } catch (error) {}
  };

  const handleVerifyOrRejectPods = async (podStatus) => {
    try {
      const body = {
        status: podStatus,
        orderId,
      };

      const response = await verifyOrRejectAllPods(body);
      if (typeof response === "string") {
        toast.error(response);
      } else {
        setOrder((prevOrder) => ({
          ...prevOrder,
          status: response.status,
          allPods: response.allPods,
        }));
        toast.success(`PODs status changed successfully!`);
      }
    } catch (error) {}
  };

  return (
    <Col span={24}>
      <Collapse defaultActiveKey={["1", "2"]}>
        <Collapse.Panel header="PODs" key="1">
          <div className="flex flex-wrap justify-around items-center">
            {allPods.map((pod) => (
              <div key={pod.label} className="max-w-[160px]">
                <Typography.Text strong className="block text-center">
                  POD ({pod.label})
                </Typography.Text>
                <Typography.Text className="block text-center">
                  {pod.label === "D1"
                    ? dropLocation?.location
                    : pod.label === "D2"
                    ? otherDropLocations?.[0]?.location
                    : pod.label === "D3"
                    ? otherDropLocations?.[1]?.location
                    : ""}
                </Typography.Text>
                <div>
                  {pod.pod || pod.courierDocketNumber ? (
                    pod.pod ? (
                      <a href={pod.pod} target="_blank" rel="noreferrer">
                        <img
                          src={pod.pod}
                          alt={`POD ${pod.label}`}
                          className="h-40 w-40"
                        />
                      </a>
                    ) : (
                      <>
                        <Typography.Text className="block text-center">
                          Courier Docket Number
                        </Typography.Text>
                        <Typography.Text className="block text-center">
                          {pod.courierDocketNumber}
                        </Typography.Text>
                      </>
                    )
                  ) : (
                    <Typography.Text
                      strong
                      className="block text-center text-red-600"
                    >
                      Not added
                    </Typography.Text>
                  )}
                </div>
                {pod?.status === POD_STATUS.SUCCESS ? (
                  <Typography.Text className="block text-center mt-3">
                    <StatusPill status={AllStatus.VERIFIED} />
                  </Typography.Text>
                ) : pod?.status === POD_STATUS.REJECTED ? (
                  <Typography.Text className="block text-center mt-3">
                    <StatusPill status={AllStatus.REJECTED} />
                  </Typography.Text>
                ) : null}
                {[OrderStatus.POD_ADDED].includes(status) &&
                pod?.status === POD_STATUS.PENDING ? (
                  pod.pod || pod.courierDocketNumber ? (
                    <div className="flex justify-between items-center mt-3">
                      <Popconfirm
                        placement="bottom"
                        style={{}}
                        title={`Are you sure to reject this loading document?`}
                        okText="Yes"
                        okButtonProps={{
                          size: "middle",
                        }}
                        cancelButtonProps={{
                          size: "middle",
                        }}
                        icon={
                          <QuestionCircleOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        }
                        onConfirm={() => {
                          handleChangePodStatus(POD_STATUS.REJECTED, pod.label);
                        }}
                      >
                        <div>
                          <Button danger>Reject</Button>
                        </div>
                      </Popconfirm>
                      <Popconfirm
                        placement="bottom"
                        style={{}}
                        title={`Are you sure to verify this loading document?`}
                        okText="Yes"
                        okButtonProps={{
                          size: "middle",
                        }}
                        cancelButtonProps={{
                          size: "middle",
                        }}
                        icon={
                          <QuestionCircleOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        }
                        onConfirm={() => {
                          handleChangePodStatus(POD_STATUS.SUCCESS, pod.label);
                        }}
                      >
                        <div>
                          <Button type="primary">Verify</Button>
                        </div>
                      </Popconfirm>
                    </div>
                  ) : null
                ) : null}
              </div>
            ))}
          </div>
          {[OrderStatus.POD_ADDED].includes(status) &&
          allPods?.length > 1 &&
          allPods.every((pod) => pod.status === POD_STATUS.PENDING) ? (
            <div className="flex justify-center items-center mt-5">
              <div>
                <Popconfirm
                  placement="bottom"
                  style={{}}
                  title={`Are you sure to reject all PODs?`}
                  okText="Yes"
                  okButtonProps={{
                    size: "middle",
                  }}
                  cancelButtonProps={{
                    size: "middle",
                  }}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => {
                    handleVerifyOrRejectPods(POD_STATUS.REJECTED);
                  }}
                >
                  <div>
                    <Button type="primary">Reject All</Button>
                  </div>
                </Popconfirm>
              </div>
              <div>
                <Popconfirm
                  placement="bottom"
                  style={{}}
                  title={`Are you sure to verify all PODs?`}
                  okText="Yes"
                  okButtonProps={{
                    size: "middle",
                  }}
                  cancelButtonProps={{
                    size: "middle",
                  }}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => {
                    handleVerifyOrRejectPods(POD_STATUS.SUCCESS);
                  }}
                >
                  <div>
                    <Button danger>Verify All</Button>
                  </div>
                </Popconfirm>
              </div>
            </div>
          ) : null}
        </Collapse.Panel>
      </Collapse>
    </Col>
  );
};

export default PodManager;
